<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Crear tipo de documento">
          <document-type-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import DocumentTypeForm from "@/components/Forms/DocumentTypeForm";

export default {
  components: {
    DocumentTypeForm,
    CCard,
  },
};
</script>
